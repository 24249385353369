<template>
  <content-layout :breadcrumbs-items="breadcrumbsItems">
    <v-overlay :value="processing">
      <cz-spinner :size="60" color="white" class="flex-grow-1" />
    </v-overlay>
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-card flat class="ma-6">
        <v-card-title>
          {{ $t('customer.irsFile') }}
        </v-card-title>
        <v-card-text class="text-body-1">
          <div>{{ $t('customer.irsFileGen.irsFileFor') }}</div>
          <div>
            {{
              $t('customer.irsFileGen.irsFileSubtitle', [account.businessId])
            }}
          </div>
          <div>
            {{
              $t('customer.irsFileGen.irsFileSubtitle2', [account.companyName])
            }}
          </div>
        </v-card-text>
        <v-card-text>
          <v-form>
            <cz-form-field :label="$t('businessTypes.authorizedDealer')">
              <cz-input
                :value="account.businessId"
                readonly
                class="font-weight-semibold"
                dense
              />
            </cz-form-field>
            <div
              class="d-flex align-center full-width"
              style="column-gap: 15px"
            >
              <cz-form-field
                :label="$t('customer.irsFileGen.periodSelection')"
                class="flex-grow-1"
                style="max-width: 330px"
              >
                <validation-provider
                  rules="required"
                  :name="$t('customer.irsFileGen.periodSelection')"
                  v-slot="{ errors }"
                >
                  <cz-autocomplete
                    v-model="form.period"
                    :items="[
                      {
                        text: $t('customer.irsFileGen.eventDate'),
                        value: 'event-date'
                      },
                      {
                        text: $t('customer.irsFileGen.balancePeriod'),
                        value: 'balance-period'
                      }
                    ]"
                    dense
                    :error-messages="errors"
                    @input="onPeriodChange"
                  />
                </validation-provider>
              </cz-form-field>
              <div
                class="d-flex flex-grow-1"
                style="column-gap: 15px"
                v-if="form.period === 'event-date'"
              >
                <cz-form-field
                  :label="$t('customer.irsFileGen.fromDate')"
                  class="flex-grow-1"
                  style="max-width: 330px"
                >
                  <validation-provider
                    rules="required"
                    :name="$t('customer.irsFileGen.fromDate')"
                    v-slot="{ errors }"
                  >
                    <cz-date-picker
                      v-model="form.from"
                      placeholder="dd/mm/yyyy"
                      dense
                      :error-messages="errors"
                      :max-date="new Date().toISOString()"
                    />
                  </validation-provider>
                </cz-form-field>
                <cz-form-field
                  :label="$t('customer.irsFileGen.toDate')"
                  class="flex-grow-1"
                  style="max-width: 330px"
                >
                  <validation-provider
                    rules="required"
                    :name="$t('customer.irsFileGen.toDate')"
                    v-slot="{ errors }"
                  >
                    <cz-date-picker
                      v-model="form.to"
                      :disabled="!form.from"
                      placeholder="dd/mm/yyyy"
                      :max-date="new Date().toISOString()"
                      dense
                      :error-messages="errors"
                      :min-date="form.from"
                    />
                  </validation-provider>
                </cz-form-field>
              </div>
              <div
                class="d-flex flex-grow-1"
                style="column-gap: 15px"
                v-else-if="form.period === 'balance-period'"
              >
                <cz-form-field
                  :label="$t('customer.irsFileGen.fromPeriod')"
                  class="flex-grow-1"
                  style="max-width: 330px"
                >
                  <validation-provider
                    rules="required"
                    :name="$t('customer.irsFileGen.fromPeriod')"
                    v-slot="{ errors }"
                  >
                    <cz-date-picker
                      v-model="form.from"
                      type="month"
                      placeholder="mm/yyyy"
                      date-format="MM/yyyy"
                      dense
                      :error-messages="errors"
                      :max-date="new Date().toISOString()"
                    />
                  </validation-provider>
                </cz-form-field>
                <cz-form-field
                  :label="$t('customer.irsFileGen.toPeriod')"
                  class="flex-grow-1"
                  style="max-width: 330px"
                >
                  <validation-provider
                    rules="required"
                    :name="$t('customer.irsFileGen.toPeriod')"
                    v-slot="{ errors }"
                  >
                    <cz-date-picker
                      v-model="form.to"
                      type="month"
                      :min-date="form.from"
                      placeholder="mm/yyyy"
                      date-format="MM/yyyy"
                      dense
                      :disabled="!form.from"
                      :error-messages="errors"
                      :max-date="new Date().toISOString()"
                    />
                  </validation-provider>
                </cz-form-field>
              </div>
            </div>
            <cz-button
              :title="$t('customer.irsFileGen.generateIrsFile')"
              color="primary"
              @click="onGenerate"
              class="mt-3"
              :disabled="invalid || processing"
            />
          </v-form>
          <div v-if="irsGenResult">
            <div class="text-body-1 mt-4">
              {{
                $t('customer.irsFileGen.irsGenSavePath', [
                  irsGenResult.fileSavePath
                ])
              }}
            </div>
            <div class="text-body-1">
              {{
                $t('customer.irsFileGen.fromDateToDate', [
                  format(new Date(irsGenResult.fromDate), 'dd/MM/yyyy'),
                  format(new Date(irsGenResult.toDate), 'dd/MM/yyyy')
                ])
              }}
            </div>
          </div>
          <v-card :max-width="1020" flat class="mt-4">
            <v-card-title class="text-body-1">{{
              $t('customer.irsFileGen.resultsTitle')
            }}</v-card-title>
            <v-card>
              <cz-data-table
                v-bind="recordsTable"
                style="max-height: 500px; overflow-y: auto"
                hide-default-footer
              />
            </v-card>

            <v-card>
              <cz-data-table
                class="mt-10"
                v-bind="docTypesTable"
                style="max-height: 500px; overflow-y: auto"
                hide-default-footer
              >
                <template #item.totalAmount="{ item }">
                  {{
                    item.totalAmount.toLocaleString('he-IL', {
                      style: 'currency',
                      currency: 'ILS'
                    })
                  }}
                </template>
              </cz-data-table>
            </v-card>

            <v-divider class="my-6" />
            <div class="mt-4" v-if="irsGenResult">
              <div class="d-flex justify-space-between">
                <div>
                  <div class="text-body-1 font-weight-semibold">
                    {{ $t('customer.irsFileGen.irsFileGenInfoMsg') }}
                  </div>
                  <div class="text-body-1 font-weight-semibold">
                    {{
                      $t('customer.irsFileGen.irsCertNum', [
                        irsGenResult.irsCertificationNum
                      ])
                    }}
                  </div>
                </div>
                <div class="text-body-1">
                  {{
                    $t('customer.irsFileGen.irsGenDateTime', [
                      format(
                        new Date(irsGenResult.creationDateTime),
                        'dd/MM/yyyy'
                      ),
                      format(new Date(irsGenResult.creationDateTime), 'HH:mm')
                    ])
                  }}
                </div>
              </div>
            </div>

            <v-alert
              v-if="irsGenResult"
              transition="fade"
              dark
              color="success"
              :icon="mdiCheck"
              class="mt-6"
              :close-icon="mdiClose"
              dismissible
            >
              {{ $t('customer.irsFileGen.irsGenAlert') }}</v-alert
            >
          </v-card>
        </v-card-text>
      </v-card>
    </validation-observer>
  </content-layout>
</template>

<script>
import {
  CzFormField,
  CzInput,
  CzIcon,
  CzButton,
  CzAutocomplete,
  CzDatePicker,
  CzDataTable,
  CzSpinner
} from '@/components';
import { mapFields } from 'vuex-map-fields';
import { generateIrsFile } from '@/core/api';
import { saveAs } from 'file-saver';
import { mapActions } from 'vuex';
import b64ToBlob from 'b64-to-blob';
import { mdiCheck, mdiClose } from '@mdi/js';
import {
  endOfMonth,
  parse,
  endOfDay,
  startOfDay,
  startOfMonth,
  isAfter,
  format
} from 'date-fns';
import cloneDeep from 'lodash.clonedeep';
const initialForm = { period: '', from: null, to: null };

Object.freeze(initialForm);

export default {
  name: 'GenerateIrsFilePage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzFormField,
    CzInput,
    CzAutocomplete,
    CzDatePicker,
    CzDataTable,
    CzButton,
    CzSpinner,
    CzIcon
  },
  computed: {
    ...mapFields('auth', ['account', 'accountId']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.irsFile'),
          disabled: true,
          exact: true
        }
      ];
    }
  },
  data() {
    return {
      mdiCheck,
      mdiClose,
      irsGenResult: null,
      processing: false,
      form: cloneDeep(initialForm),
      recordsTable: {
        columns: [
          {
            text: this.$t('customer.irsFileGen.recordCode'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'code',
            sortable: false
          },
          {
            text: this.$t('customer.irsFileGen.recordDesc'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'description',
            sortable: false
          },
          {
            text: this.$t('customer.irsFileGen.recordsNum'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'count',
            sortable: false
          }
        ],
        items: []
      },
      docTypesTable: {
        columns: [
          {
            text: 'מספר מסמך',
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'docNumber',
            sortable: false
          },
          {
            text: 'סוג מסמך',
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'docType',
            sortable: false
          },
          {
            text: 'סה״כ כמותי',
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'totalQuantity',
            sortable: false
          },
          {
            text: 'סה״כ כספי',
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'totalAmount',
            sortable: false
          }
        ],
        items: []
      }
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    format,
    onPeriodChange() {
      setTimeout(() => {
        this.form.to = null;
      }, 2000);
    },
    async onGenerate() {
      try {
        this.processing = true;
        const _form = cloneDeep(this.form);

        if (_form.period === 'balance-period') {
          _form.from = startOfMonth(parse(_form.from, 'MM/yyyy', new Date()));
          const endOfMonthVal = endOfMonth(
            parse(_form.to, 'MM/yyyy', new Date())
          );
          if (isAfter(endOfMonthVal, new Date())) {
            _form.to = parse(_form.to, 'MM/yyyy', new Date());
          } else {
            _form.to = endOfMonth(parse(_form.to, 'MM/yyyy', new Date()));
          }
        } else {
          _form.from = startOfDay(parse(_form.from, 'dd/MM/yyyy', new Date()));
          _form.to = endOfDay(parse(_form.to, 'dd/MM/yyyy', new Date()));
        }

        this.irsGenResult = await generateIrsFile(this.accountId, {
          ..._form,
          accountId: this.accountId
        });
        this.recordsTable.items = this.irsGenResult.records;
        this.docTypesTable.items = this.irsGenResult.docTypes;
        const blob = b64ToBlob(this.irsGenResult.zipContent, 'application/zip');
        saveAs(blob, `OPENFRMT.zip`);

        this.form = cloneDeep(initialForm);
        this.$refs.observer.reset();
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.processing = false;
      }
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped></style>
